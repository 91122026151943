import { AppSidebar } from "@/components/app-sidebar.tsx"
import { RequestAccessForm } from "@/components/request-access-form.tsx"
import { Button } from "@/components/ui/button.tsx"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet.tsx"
import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar.tsx"
import { AnalyticsProvider } from "@/context/analytics.tsx"
import { Session, useAuth, useLogout } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc.ts"
import { cn } from "@/lib/tw-utils.ts"
import {
  Book,
  Bot,
  CircleUser,
  FileText,
  Menu,
  MessageCircle,
  MessageCircleQuestion,
  ScrollText,
  Users,
} from "lucide-react"
import React from "react"
import { Link, NavLink, Outlet, useLocation } from "react-router"

export function AppLayout() {
  const { session } = useAuth()
  const logout = useLogout()
  const [chatBots, setChatBots] = React.useState<ChatBotsResult | undefined>(undefined)

  React.useEffect(() => {
    const fetchChatBots = async () => {
      try {
        const chatBots = await trpc.chatbots.list.query()
        setChatBots(chatBots)
      } catch (err) {
        // TODO: Add a more robust error handler, with explicit check for an invalid session
        console.error(err)
        logout()
      }
    }
    if (!session) {
      window.location.href = "/auth/login?redirect=" + encodeURIComponent(window.location.pathname)
    } else {
      fetchChatBots()
    }
  }, [session])

  const contents =
    !session || chatBots === undefined ? (
      <div className="flex h-screen items-center justify-center">Loading...</div>
    ) : chatBots.length === 0 && session.account.role === "user" ? (
      <div className="h-full max-h-full w-full max-w-full">
        <main className="relative h-full max-h-full overflow-auto">
          <div className="h-full w-full pt-10">
            <RequestAccessForm />
          </div>
        </main>
      </div>
    ) : (
      <LoggedInAppLayout chatBots={chatBots} session={session} />
    )

  return <AnalyticsProvider>{contents}</AnalyticsProvider>
}

type ChatBotsResult = Awaited<ReturnType<typeof trpc.chatbots.list.query>>

export type AppOutletContext = {
  setTitle: (title: string) => void
  mode: "simple" | "complex"
}

function LoggedInAppLayout(props: { chatBots: ChatBotsResult; session: Session }) {
  return (
    <SidebarProvider>
      <AppSidebar chatBots={props.chatBots} session={props.session} />
      <SidebarInset>
        <Outlet
          context={{ setTitle: () => undefined, mode: "complex" } satisfies AppOutletContext}
        />
      </SidebarInset>
    </SidebarProvider>
  )
}
