import { useAuth } from "@/context/auth.tsx"
import React from "react"
import { useNavigate } from "react-router"

export function AuthCallbackPage() {
  const { session } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (session) {
      navigate(`/`)
    }
  }, [session])

  return <div className="flex h-screen items-center justify-center">Loading...</div>
}
