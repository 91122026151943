import { DataTable } from "@/components/datatable.tsx"
import { SelectField } from "@/components/hook-form/select-field.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { Form, FormField } from "@/components/ui/form.tsx"
import { Input } from "@/components/ui/input.tsx"
import { Label } from "@/components/ui/label.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc.ts"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router"
import invariant from "tiny-invariant"
import { z } from "zod"

export async function userEditPageLoader({ params }: LoaderFunctionArgs) {
  const account = authenticateGuard()
  invariant(account.role === "admin", "Account is required")
  invariant(params.userId, "userId is required")
  const [user, accessRequests] = await Promise.all([
    trpc.users.get.query({ accountId: params.userId }),
    trpc.users.listAccessRequestsForUser.query({ accountId: params.userId }),
  ])
  return { user, accessRequests }
}

type LoaderData = Awaited<ReturnType<typeof userEditPageLoader>>

const formSchema = z.object({
  role: z.enum(["user", "admin"], {
    message: "Please select a valid role.",
  }),
})

export function UserEditPage() {
  const data = useLoaderData() as LoaderData

  const navigate = useNavigate()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      role: data.user.role,
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    await trpc.users.update.mutate({
      accountId: data.user.id,
      role: values.role,
    })
    navigate("/admin/users")
  }

  return (
    <>
      <H1 className="mb-4">Update User</H1>
      <Lead className="mb-8 text-balance">Review and manage a users access below.</Lead>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-6">
          <Card>
            <CardHeader>
              <CardTitle>User Details</CardTitle>
              <CardDescription>These cannot be edited</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-6">
              <div className="grid gap-3">
                <Label htmlFor="email">Email</Label>
                <Input
                  readOnly
                  id="email"
                  type="text"
                  className="w-full"
                  defaultValue={data.user.email}
                />
              </div>
              <div className="grid gap-3">
                <Label htmlFor="joined">Joined</Label>
                <Input
                  id="joined"
                  type="text"
                  defaultValue={data.user.timeCreated}
                  className="w-full"
                  readOnly
                />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Access Level</CardTitle>
              <CardDescription>Define the users security level</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-6">
              <div className="grid gap-3">
                <FormField
                  control={form.control}
                  name="role"
                  disabled={form.formState.isSubmitting || form.formState.isLoading}
                  render={({ field }) => (
                    <SelectField
                      label="Role"
                      field={field}
                      options={[
                        { value: "user", label: "User" },
                        { value: "admin", label: "Admin" },
                      ]}
                    />
                  )}
                />
              </div>
            </CardContent>
          </Card>

          {data.accessRequests.length === 0 ? null : (
            <Card>
              <CardHeader>
                <CardTitle>Access Requests</CardTitle>
                <CardDescription>
                  Below are requests that have been issued by this user
                </CardDescription>
              </CardHeader>
              <CardContent className="grid gap-6">
                <DataTable
                  columns={[
                    { accessorKey: "timeUpdated", header: "Issued" },
                    { accessorKey: "company", header: "Company" },
                    { accessorKey: "role", header: "Role" },
                    { accessorKey: "reason", header: "Reason" },
                  ]}
                  data={data.accessRequests}
                />
              </CardContent>
            </Card>
          )}

          <div className="flex flex-grow justify-between">
            <div className="flex gap-3">
              <Button
                type="submit"
                variant="default"
                disabled={form.formState.isSubmitting || form.formState.isLoading}
              >
                Update User
              </Button>
              <Button
                type="button"
                variant="secondary"
                disabled={form.formState.isSubmitting || form.formState.isLoading}
                onClick={() => {
                  navigate("/admin/users")
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="flex gap-3"></div>
          </div>
        </form>
      </Form>
    </>
  )
}
