import { DataTable } from "@/components/datatable.tsx"
import { Badge } from "@/components/ui/badge.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Label } from "@/components/ui/label.tsx"
import { Textarea } from "@/components/ui/textarea.tsx"
import { H1, H2, H3, H4, Ol, P, Ul } from "@/components/ui/typography.tsx"
import { authHeaders } from "@/context/auth.tsx"
import { cn } from "@/lib/tw-utils.ts"
import { getErrorMessage } from "@ai/core/util/errors/get-error-message.ts"
import { Bot, CornerDownLeft, User } from "lucide-react"
import React from "react"
import Markdown from "react-markdown"
import { BeatLoader } from "react-spinners"
import { log } from "../log.ts"
import { Card } from "./ui/card.tsx"

export type AssistantMessageTextPart = { type: "text"; content: string }
export type AssistantMessageComponentPart = { type: "component"; config: any }
export type AssistantMessagePart = AssistantMessageTextPart | AssistantMessageComponentPart
export type AssistantMessage = { role: "assistant"; parts: AssistantMessagePart[]; batch: number }
export type UserMessage = { role: "user"; content: string }
export type ChatBotMessage = AssistantMessage | UserMessage

// TODO: We should centralize these tags
const COMPONENT_START_TAG = "```custom_component"
const COMPONENT_END_TAG = "```"

export type UseChatBotEndpointArgs = {
  endpointUrl: string
  endpointBody: (userMessage: string, threadId: string) => Record<string, string>
  startThread: () => Promise<string>
  threadId?: string
  setMessages: React.Dispatch<React.SetStateAction<ChatBotMessage[]>>
  onUserMessageSent?: (userMessage: string, threadId: string) => any
}

export function useChatBotEndpoint(args: UseChatBotEndpointArgs) {
  const threadRef = React.useRef<string | undefined>(args.threadId)

  const batchIdx = React.useRef<number>(0)

  const [waitingForResponse, setWaitingForResponse] = React.useState(false)
  const [streamingResponse, setStreamingResponse] = React.useState(false)
  const [streamChunkCount, setStreamChunkCount] = React.useState(0)

  // Make sure to update when the threadId changes
  // TODO: Should this reset the messages?
  React.useEffect(() => {
    if (args.threadId) {
      threadRef.current = args.threadId
    }
  }, [args.threadId])

  const onSendUserMessage = React.useCallback(
    async (userMessage: string) => {
      // If the User hasn't actually typed anything then don't do anything
      if (waitingForResponse) return
      if (userMessage.trim() === "") return

      // Add the User message to the messages list
      args.setMessages((prevMessages) => [...prevMessages, { role: "user", content: userMessage }])

      // Increment the batch index
      batchIdx.current += 1

      // Indicate to the UI that we will be waiting on the Assistant response
      setWaitingForResponse(true)

      let startedRendering = false

      const addAssistantMessagePart = <T extends AssistantMessagePart>(partToAdd: T): T => {
        if (!startedRendering) {
          // First part of the response
          args.setMessages((prevMsgs) => [
            ...prevMsgs,
            {
              role: "assistant",
              parts: [partToAdd],
              batch: batchIdx.current,
            },
          ])
          startedRendering = true
        } else {
          // Follow up part/chunk of the response
          args.setMessages((prevMsgs) => {
            const recentMsg = prevMsgs[prevMsgs.length - 1]

            const isRecentMsgInResponseBatch =
              recentMsg && recentMsg.role === "assistant" && recentMsg.batch === batchIdx.current

            if (isRecentMsgInResponseBatch) {
              // create a working copy of the recent message that we will amend/replace
              const recentMsgReplacement = {
                ...recentMsg,
                parts: [...recentMsg.parts],
              }

              if (partToAdd.type === "text") {
                // create a handle on the recent part of the recent message - we will potentially amend this value
                const recentPart = recentMsgReplacement.parts[recentMsgReplacement.parts.length - 1]
                if (!recentPart || recentPart.type !== "text") {
                  // there was no recent part, or the recent part was not a text part, so we will just add in a new text part
                  recentMsgReplacement.parts.push(partToAdd)
                } else if (recentPart.type === "text") {
                  // Append the text to the existing text part type
                  partToAdd = {
                    ...partToAdd,
                    content: recentPart.content + partToAdd.content, // append the text
                  }
                  recentMsgReplacement.parts.splice(recentMsg.parts.length - 1, 1, partToAdd)
                } else {
                  log.warn(
                    "Potential issue in chatbot response parsing. Recent part data was not of expected state.",
                  )
                }
              } else if (partToAdd.type == "component") {
                recentMsgReplacement.parts.push(partToAdd)
              } else {
                throw new Error(
                  `Unexpected part type in chatbot response parsing: ${JSON.stringify(partToAdd)}`,
                )
              }
              return [...prevMsgs.slice(0, prevMsgs.length - 1), recentMsgReplacement]
            } else {
              // This might be an invalid state? We should have a previous message in the same batch if the startedRendering flag was set.
              log.warn(
                "Potential issue in chatbot response parsing. The startedRendering flag did marry up to actual stored message state.",
              )
              return [
                ...prevMsgs,
                {
                  role: "assistant",
                  parts: [partToAdd],
                  batch: batchIdx.current,
                },
              ]
            }
          })
        }

        // We return the part that was added, as this might have been amended
        return partToAdd
      }

      const parseComponentConfig = (componentConfig: string) => {
        try {
          const config = JSON.parse(componentConfig.trim())
          return config
        } catch (err) {
          log.error("Failed to parse component config", getErrorMessage(err))
          throw err
        }
      }

      try {
        let threadId = threadRef.current
        if (!threadId) {
          log.debug("Starting new thread")
          threadId = await args.startThread()
          threadRef.current = threadId
        } else {
          log.debug("Using existing thread")
        }

        log.debug("Sending user message to endpoint: ", args.endpointUrl)

        const response = await fetch(args.endpointUrl, {
          method: "POST",
          headers: {
            ...authHeaders(),
            "Content-Type": "application/json",
            Accept: "text/plain",
          },
          body: JSON.stringify(args.endpointBody(userMessage, threadId)),
        })

        if (!response.ok || !response.body) {
          throw new Error("Failed to send message")
        }

        args.onUserMessageSent?.(userMessage, threadId)

        const reader = response.body.getReader()
        const decoder = new TextDecoder("utf-8")

        const readStream = async () => {
          log.debug(`Reading response stream`)
          const { done, value } = await reader!.read()
          let chunk = decoder.decode(value, { stream: !done })
          log.debug(`Handling stream chunk:`, chunk)
          if (done) {
            log.debug(`Stream is done`)
          }
          return { done, chunk }
        }

        while (true) {
          let streamResponse = await readStream()

          let workingBuffer = streamResponse.chunk

          // This is special handling for local development. We use the `lambda-stream` package to enable running of streaming lambdas in development, but it doesn't do proper streaming in local development, and the responses are wrapped into a JSON structure.
          if (import.meta.env.VITE_LOCAL === "true") {
            if (workingBuffer.startsWith("{")) {
              log.debug("Unwrapping lambda-stream JSON response.")
              const data = JSON.parse(workingBuffer)
              workingBuffer = data.body
            }
          }

          // Is there a tick in the chunk?
          if (!streamResponse.done && workingBuffer.indexOf("`") !== -1) {
            // Yes, let's start buffering
            log.debug("Response chunk contains a tick, buffering")

            // Loop until we finish buffering
            while (true) {
              streamResponse = await readStream()
              workingBuffer += streamResponse.chunk

              if (streamResponse.done || workingBuffer.length >= COMPONENT_START_TAG.length * 2) {
                log.debug("Tick buffering completed")
                break
              }
            }
          }

          // We will loop until we process all component definitions
          while (true) {
            // Is there a new component definition in the chunk?

            let componentStartIdx = workingBuffer.indexOf(COMPONENT_START_TAG)
            if (componentStartIdx === -1) {
              // No component definition found
              break
            }

            log.debug("Found component definition start tag")

            // Is there some leading text before the component definition?
            if (componentStartIdx > 0) {
              // Yes, lets extract that as text

              const leadingText = workingBuffer.substring(0, componentStartIdx)

              log.debug("Found leading text before component definition:", leadingText)

              addAssistantMessagePart({
                type: "text",
                content: leadingText,
              })

              // Overwrite the working buffer as we have processed the leading text
              workingBuffer = workingBuffer.substring(componentStartIdx)

              // The component now begins at index 0 of our working buffer
              componentStartIdx = 0
            }

            let componentEndIdx = workingBuffer.indexOf(
              COMPONENT_END_TAG,
              componentStartIdx + COMPONENT_START_TAG.length,
            )

            // Buffer until we get the end of the component definition
            while (componentEndIdx === -1) {
              if (streamResponse.done) {
                throw new Error("Stream is done, but we have an incomplete component definition")
              }
              streamResponse = await readStream()
              workingBuffer += streamResponse.chunk
              componentEndIdx = workingBuffer.indexOf(
                COMPONENT_END_TAG,
                componentStartIdx + COMPONENT_START_TAG.length,
              )
            }

            // Extract the component config (from within the start/end tags)
            const componentConfig = workingBuffer.substring(
              COMPONENT_START_TAG.length,
              componentEndIdx,
            )
            log.debug("Processing component config:", componentConfig)

            // Add the component part
            addAssistantMessagePart({
              type: "component",
              config: parseComponentConfig(componentConfig),
            })

            // Remove the processed component from the working buffer
            workingBuffer = workingBuffer.substring(componentEndIdx + COMPONENT_END_TAG.length)
          }

          // Is there any content in our working buffer?
          if (workingBuffer.length > 0) {
            // Yes, add it as a text part
            addAssistantMessagePart({ type: "text", content: workingBuffer })
          }

          setStreamChunkCount((prev) => prev + 1)

          log.debug(`Finished stream loop`)

          // Is the streaming done?
          if (streamResponse.done) {
            // Break out our loop!
            break
          }
        }

        // Release the stream reader lock
        reader.releaseLock()
      } catch {
        // TODO: We need much more robust handling of errors
        addAssistantMessagePart({
          type: "text",
          content: `

Sorry, I was not able to complete my response at this moment. Please try again later.`,
        })
      } finally {
        // Unlock the UI
        setStreamingResponse(() => false)
        setWaitingForResponse(() => false)
      }
    },
    [args.endpointUrl, args.setMessages, args.onUserMessageSent],
  )

  return {
    onSendUserMessage,
    waitingForResponse,
    streamingResponse,
    streamChunkCount,
  }
}

export type ChatbotProps = {
  messages: ChatBotMessage[]
  waitingForResponse: boolean
  streamingResponse: boolean
  streamChunkCount: number
  onSendUserMessage: (userMessage: string) => Promise<void>
}

export function Chatbot(props: ChatbotProps) {
  const [userMessage, setUserMessage] = React.useState("")

  return (
    <div className="grid h-full w-full grid-cols-1 grid-rows-[1fr,auto] overflow-hidden">
      <div className="overflow-auto">
        <div className="mx-auto flex w-full max-w-7xl flex-col gap-4 px-8 pb-4 pt-[70px]">
          {props.messages.map((message, index) => {
            let msgElement: JSX.Element

            if (message.role === "assistant") {
              msgElement = (
                <AssistantMessage key={index}>
                  {message.parts.map((part, index) => {
                    if (part.type === "text") {
                      return <StyledMarkdown key={index}>{part.content}</StyledMarkdown>
                    } else if (part.type === "component") {
                      return <CustomComponent key={index} config={part.config} />
                    } else {
                      throw new Error(`Invalid part: ${JSON.stringify(part)}`)
                    }
                  })}
                </AssistantMessage>
              )
            } else if (message.role === "user") {
              msgElement = <UserMessage key={index}>{message.content}</UserMessage>
            } else {
              throw new Error(`Invalid message: ${JSON.stringify(message)}`)
            }

            return index === props.messages.length - 1 ? (
              <ScrollIntoView key={index}>{msgElement}</ScrollIntoView>
            ) : (
              msgElement
            )
          })}
          {props.waitingForResponse && !props.streamingResponse && (
            <ScrollIntoView>
              <AssistantMessage className="animate-pulse">
                <BeatLoader color="#1E3A8A" />
              </AssistantMessage>
            </ScrollIntoView>
          )}
          {props.waitingForResponse && props.streamingResponse && (
            <ScrollIntoView key={props.streamChunkCount}>
              <div />
            </ScrollIntoView>
          )}
        </div>
      </div>
      <div>
        <div className="mx-auto w-full max-w-7xl px-4 py-4">
          <form className="bg-background focus-within:ring-ring relative h-full overflow-hidden rounded-lg border focus-within:ring-1">
            <Label htmlFor="message" className="sr-only">
              Message
            </Label>
            <Textarea
              autoFocus
              id="message"
              placeholder="Type your message here..."
              className="h-full resize-none border-0 p-3 shadow-none focus-visible:ring-0 lg:text-lg"
              onChange={(event) => setUserMessage(event.target.value)}
              onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault()
                  setUserMessage("")
                  props.onSendUserMessage(userMessage)
                }
              }}
              value={userMessage}
            />
            <div className="absolute bottom-1 right-1">
              <Label htmlFor="send-message" className="sr-only">
                Send Message
              </Label>
              <Button
                disabled={props.waitingForResponse}
                id="send-message"
                type="button"
                size="sm"
                className="ml-auto gap-1.5"
                onClick={() => {
                  setUserMessage("")
                  props.onSendUserMessage(userMessage)
                }}
              >
                <CornerDownLeft className="size-3.5" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

function UserMessage({ children, className }: { children: string; className?: string }) {
  return (
    <div className={cn("relative flex flex-row gap-4", className)}>
      <div>
        <Badge
          variant="secondary"
          className="text-foreground relative h-8 w-8 overflow-hidden font-bold"
        >
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
            <User className="w-6 md:w-7" />
          </div>
        </Badge>
      </div>
      <div className="flex-grow">
        <StyledMarkdown>{children}</StyledMarkdown>
      </div>
    </div>
  )
}

function AssistantMessage({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div className={cn("relative flex flex-row gap-4", className)}>
      <div>
        <Badge className="text-foreground relative h-8 w-8 font-bold md:h-9 md:w-9">
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
            <Bot className="w-6 md:w-7" />
          </div>
        </Badge>
      </div>
      <div className="max-w-full flex-grow">{children}</div>
    </div>
  )
}

function StyledMarkdown({ children }: { children: string }) {
  return (
    <Markdown
      skipHtml
      components={{
        h1: (props) => <H1 className="">{props.children}</H1>,
        h2: (props) => <H2 className="">{props.children}</H2>,
        h3: (props) => <H3 className="">{props.children}</H3>,
        h4: (props) => <H4 className="">{props.children}</H4>,
        p: (props) => <P className="mb-6">{props.children}</P>,
        ul: (props) => <Ul className="">{props.children}</Ul>,
        ol: (props) => <Ol className="">{props.children}</Ol>,
        a: (props) => <a {...props} className="text-primary underline" />,
      }}
    >
      {children}
    </Markdown>
  )
}

function CustomComponent({ config }: { config: any }) {
  if (config.type === "datatable") {
    const columns = config.data.headers.map((header: any, idx: any) => ({
      accessorKey: idx.toString(),
      header,
    }))
    return (
      <>
        <H3>{config.data.title}</H3>
        <P>{config.data.description}</P>
        <Card className="mb-6 max-w-full overflow-auto [&:not(:first-child)]:mt-6">
          <DataTable columns={columns} data={config.data.rows} />
        </Card>
      </>
    )
  }
  throw new Error(`Invalid component config: ${JSON.stringify(config)}`)
}

function ScrollIntoView(props: { children: React.ReactNode }) {
  const ref = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView()
    }
  }, [])

  return <div ref={ref}>{props.children}</div>
}
