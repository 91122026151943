import { Section } from "@/components/ui/page-layout.tsx"
import { H1, H2, Ul } from "@/components/ui/typography.tsx"

export function ReleaseNotesPage() {
  return (
    <>
      <H1>Release Notes</H1>

      <Section>
        <H2 className="flex items-center justify-between">
          <span>1.2.0</span> <span className="text-sm italic">2024/12/13</span>
        </H2>
        <Ul>
          <li>
            <strong>User Interface</strong>
            <Ul>
              <li>Redesigned the user interface</li>
              <li>Added a new navigation menu</li>
              <li>Added a new sidebar</li>
              <li>Added a new layout</li>
              <li>Added new components</li>
              <li>Improved the styling of the application</li>
            </Ul>
          </li>
          <li>
            <strong>ChatBots</strong>
            <Ul>
              <li>Ability to mark a ChatBot as for internal use only.</li>
              <li>
                Chat with datalake capability has been introduced.
                <br />
                <strong className="text-destructive">
                  Please ensure that this is only used internally. It is an experimental feature.
                </strong>
              </li>
              <li>
                Chat Threads capability has been introduced.
                <br />
                <em>
                  Only the configuration and backend changes for this have been implemented at the
                  moment. The UI changes will follow soon.
                </em>
              </li>
              <li>
                Context Panel capability has been introduced.
                <br />
                <em>
                  Only the configuration and backend changes for this have been implemented at the
                  moment. The UI changes will follow soon.
                </em>
              </li>
            </Ul>
          </li>
          <li>
            <strong>Housekeeping</strong>
            <Ul>
              <li>Upgraded our infrastructure</li>
              <li>Upgraded our dependencies</li>
              <li>Various small bug fixes</li>
            </Ul>
          </li>
        </Ul>
      </Section>

      <Section>
        <H2 className="flex items-center justify-between">
          <span>1.1.0</span> <span className="text-sm italic">2024/11/28</span>
        </H2>
        <Ul>
          <li>
            <strong>Chat with Datalake Prototype</strong>
            <Ul>
              <li>
                Improved resolution of database entities based on name. The agent will now verify
                the record that the user is trying to refer to. If the record cannot be identified
                the agent will assist the user in identifying the correct record.
              </li>
            </Ul>
          </li>
          <li>
            <strong>Housekeeping</strong>
            <Ul>
              <li>Upgraded dependencies</li>
            </Ul>
          </li>
        </Ul>
      </Section>

      <Section>
        <H2>1.0.0</H2>
        <Ul>
          <li>Initial release</li>
        </Ul>
      </Section>
    </>
  )
}
