import { Main } from "@/components/layout.tsx"
import { Outlet } from "react-router"

export function AdminLayout() {
  return (
    <Main>
      <Outlet />
    </Main>
  )
}
