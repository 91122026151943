import { InputField } from "@/components/hook-form/input-field.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Form, FormField } from "@/components/ui/form.tsx"
import { Seperator } from "@/components/ui/seperator.tsx"
import { Ul } from "@/components/ui/typography.tsx"
import { useAuthUrls } from "@/context/auth.tsx"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"

const emailFormSchema = z.object({
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
})

export function AuthLoginPage() {
  const loginUrls = useAuthUrls()

  const params = React.useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    return { error: params.get("error") || "" }
  }, [])

  const emailForm = useForm<z.infer<typeof emailFormSchema>>({
    resolver: zodResolver(emailFormSchema),
    defaultValues: {
      email: "",
    },
  })

  async function onEmailSubmit(values: z.infer<typeof emailFormSchema>) {
    window.location.href = loginUrls.email({ email: values.email })
    await new Promise((resolve) => setTimeout(resolve, 1000))
  }

  return (
    <>
      <div className="grid gap-2 text-center"></div>
      <div className="grid gap-6">
        <h1 className="gap-4 text-2xl font-bold">NetLife AI</h1>
        <p className="text-muted-foreground text-balance">
          Get started by providing your email address below.
        </p>
      </div>
      <Seperator label="or" className="text-muted-foreground hidden" />
      <Form {...emailForm}>
        <form onSubmit={emailForm.handleSubmit(onEmailSubmit)}>
          <div className="grid gap-8">
            <FormField
              control={emailForm.control}
              name="email"
              disabled={emailForm.formState.isSubmitting || emailForm.formState.isLoading}
              render={({ field }) => (
                <InputField field={field} autoComplete="email" placeholder="janedoe@example.com" />
              )}
            />
            <Button
              type="submit"
              className="gap-2"
              size="sm"
              disabled={emailForm.formState.isSubmitting || emailForm.formState.isLoading}
            >
              Login with Email
            </Button>
            <div className="text-muted-foreground text-sm">
              By continuing you agree to our{" "}
              <a href="/terms-of-service" target="_blank" className="underline">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="/privacy-policy" target="_blank" className="underline">
                Privacy Policy
              </a>
              .
            </div>
          </div>
        </form>
      </Form>
      {params.error && (
        <div className="text-sm text-red-500">
          There was an error whilst trying to complete your login. Some of the reasons this could
          occur are listed below.
          <Ul className="lg:text-sm">
            <li>
              You used the login code on a different browser/device compared to the one you started
              the login on.
            </li>
            <li>Your login code has expired.</li>
          </Ul>
          Please try again, and if the problem persists reach out to our support team.
        </div>
      )}
    </>
  )
}
