import { DataTable } from "@/components/datatable.tsx"
import { Button } from "@/components/ui/button.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { trpc } from "@/lib/trpc.ts"
import { ColumnDef } from "@tanstack/react-table"
import { useLoaderData, useNavigate } from "react-router"

export async function knowledgeListPageLoader() {
  const knowledge = await trpc.knowledge.list.query()
  return { knowledge }
}

type LoaderData = Awaited<ReturnType<typeof knowledgeListPageLoader>>

type Record = LoaderData["knowledge"][number]

export const columns: ColumnDef<Record>[] = [
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    header: "Training Progress",
    cell: ({ row }) => {
      const record = row.original
      if (!record.sourceFileCount) return "0%"
      const perc =
        record.sourceFileCount === 0
          ? 100
          : Math.floor(
              ((record.syncFailedFileCount + record.syncedFileCount) / record.sourceFileCount) *
                100,
            )
      return `${perc} %`
    },
  },
  {
    accessorKey: "syncFailedFileCount",
    header: "Training Fails",
  },
  {
    accessorKey: "syncedFileCount",
    header: "Training Success",
  },
]

export function KnowledgeListPage() {
  const data = useLoaderData() as LoaderData
  const navigate = useNavigate()
  return (
    <>
      <H1 className="mb-4">Knowledge</H1>
      <Lead className="mb-8 text-balance">
        Knowledge are the information sources used by ChatBots. We separate the management of
        Knowledge from ChatBots to enable the ability to share Knowledge across multiple ChatBots.
        This allows us to create different ChatBot personas over shared Knowledge.
      </Lead>
      <div className="mb-4 flex justify-end">
        <Button variant="default" size="sm" onClick={() => navigate("/admin/knowledge/create")}>
          Create Knowledge
        </Button>
      </div>
      <DataTable
        columns={columns}
        data={data.knowledge}
        onRowClicked={(record) => navigate(`/admin/knowledge/${record.id}`)}
      />
    </>
  )
}
