import { AdminLayout } from "./layouts/admin-layout.tsx"
import { AppLayout } from "./layouts/app-layout.tsx"
import { AuthLayout } from "./layouts/auth-layout.tsx"
import { ErrorLayout } from "./layouts/error-layout.tsx"
import { ChatBotCreatePage, chatBotCreatePageLoader } from "./pages/admin/chatbot-create-page.tsx"
import { ChatBotEditPage, chatBotEditPageLoader } from "./pages/admin/chatbot-edit-page.tsx"
import { ChatBotListPage, chatBotListPageLoader } from "./pages/admin/chatbot-list-page.tsx"
import { GdriveListPage, gdriveListPageLoader } from "./pages/admin/gdrive-list-page-v2.tsx"
import {
  KnowledgeCreatePage,
  knowledgeCreatePageLoader,
} from "./pages/admin/knowledge-create-page.tsx"
import { KnowledgeEditPage, knowledgeEditPageLoader } from "./pages/admin/knowledge-edit-page.tsx"
import { KnowledgeListPage, knowledgeListPageLoader } from "./pages/admin/knowledge-list-page.tsx"
import { ReleaseNotesPage } from "./pages/admin/release-notes-page.tsx"
import { UserEditPage, userEditPageLoader } from "./pages/admin/user-edit-page.tsx"
import { UsersListPage, usersListPageLoader } from "./pages/admin/users-list-page.tsx"
import { ChatBotPage, chatBotPageLoader } from "./pages/app/chatbot-page.tsx"
import { dashboardLoader, DashboardPage } from "./pages/app/dashboard-page.tsx"
import { PrototypePage, prototypePageLoader } from "./pages/app/prototype-page.tsx"
import { AuthCallbackPage } from "./pages/auth/auth-callback-page.tsx"
import { AuthCodePage } from "./pages/auth/auth-code-page.tsx"
import { AuthLoginPage } from "./pages/auth/auth-login-page.tsx"
import { CatchAllPage, catchAllPageLoader } from "./pages/catch-all-page.tsx"
import { ErrorPage } from "./pages/error-page.tsx"
import { Error403Page } from "./pages/errors/403.tsx"
import { Error500Page } from "./pages/errors/500.tsx"
import { PrivacyPage } from "./pages/public/privacy-page.tsx"
import { TermsOfServicePage } from "./pages/public/terms-page.tsx"

export const routes = [
  // PUBLIC ROUTES
  {
    // NOTE: This route has been lifted out as we don't want to use the same layout as other auth pages
    path: "auth/callback",
    element: <AuthCallbackPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "auth",
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "login",
        element: <AuthLoginPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "code",
        element: <AuthCodePage />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  // "PUBLIC" ROUTES
  {
    path: "errors",
    element: <ErrorLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "403",
        element: <Error403Page />,
        errorElement: <ErrorPage />,
      },
      {
        path: "500",
        element: <Error500Page />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "privacy-policy",
    errorElement: <ErrorPage />,
    element: <PrivacyPage />,
  },
  {
    path: "terms-of-service",
    errorElement: <ErrorPage />,
    element: <TermsOfServicePage />,
  },
  // APP ROUTES
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    // TODO: Add a "catch-all" route that checks to see if it is a slug or a 404
    children: [
      {
        index: true,
        errorElement: <ErrorPage />,
        element: <DashboardPage />,
        loader: dashboardLoader,
      },
      {
        path: "chat",
        errorElement: <ErrorPage />,
        children: [
          {
            path: ":chatBotId",
            errorElement: <ErrorPage />,
            element: <ChatBotPage />,
            loader: chatBotPageLoader,
          },
        ],
      },
      {
        path: "admin/prototype",
        loader: prototypePageLoader,
        errorElement: <ErrorPage />,
        element: <PrototypePage />,
      },
      // ADMIN ROUTES
      {
        path: "admin",
        element: <AdminLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "chatbots",
            children: [
              {
                index: true,
                element: <ChatBotListPage />,
                loader: chatBotListPageLoader,
                errorElement: <ErrorPage />,
              },
              {
                path: "create",
                errorElement: <ErrorPage />,
                element: <ChatBotCreatePage />,
                loader: chatBotCreatePageLoader,
              },
              {
                path: ":chatBotId",
                errorElement: <ErrorPage />,
                element: <ChatBotEditPage />,
                loader: chatBotEditPageLoader,
              },
            ],
          },
          {
            path: "files",
            children: [
              {
                index: true,
                element: <GdriveListPage />,
                loader: gdriveListPageLoader,
                errorElement: <ErrorPage />,
              },
            ],
          },
          {
            path: "knowledge",
            children: [
              {
                index: true,
                element: <KnowledgeListPage />,
                loader: knowledgeListPageLoader,
                errorElement: <ErrorPage />,
              },
              {
                path: "create",
                errorElement: <ErrorPage />,
                element: <KnowledgeCreatePage />,
                loader: knowledgeCreatePageLoader,
              },
              {
                path: ":knowledgeId",
                errorElement: <ErrorPage />,
                element: <KnowledgeEditPage />,
                loader: knowledgeEditPageLoader,
              },
            ],
          },
          {
            path: "users",
            children: [
              {
                index: true,
                element: <UsersListPage />,
                loader: usersListPageLoader,
                errorElement: <ErrorPage />,
              },
              {
                path: ":userId",
                errorElement: <ErrorPage />,
                element: <UserEditPage />,
                loader: userEditPageLoader,
              },
            ],
          },
          {
            path: "release-notes",
            element: <ReleaseNotesPage />,
          }
        ],
      },
    ],
  },
  // CATCH ALL ROUTE
  // Handles custom "slug" routes as well as 404s
  {
    path: ":slug",
    loader: catchAllPageLoader,
    element: <CatchAllPage />,
  },
]

export const ROOT_ROUTES = ["admin", "auth", "errors", "privacy", "terms", "chatbots", "chat"]
