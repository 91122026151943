import { H1, P } from "@/components/ui/typography.tsx"
import { trpc } from "@/lib/trpc.ts"
import React from "react"
import { LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router"

export async function catchAllPageLoader(args: LoaderFunctionArgs) {
  const slug = args.params.slug
  if (slug) {
    // determine if there is a valid chat bot for this
    const chatBot = await trpc.chatbots.getBySlug.query({ slug })
    if (chatBot) {
      return { redirect: `/chat/${slug}` }
    }
  }
  return {}
}

type LoaderData = Awaited<ReturnType<typeof catchAllPageLoader>>

export function CatchAllPage() {
  const data = useLoaderData() as LoaderData
  const navigate = useNavigate()

  React.useEffect(() => {
    if (data.redirect) {
      navigate(data.redirect)
    }
  }, [data.redirect])

  if (data.redirect) {
    return null
  }

  return (
    <>
      <H1>ERROR 404</H1>
      <P className="text-balance">The page you have requested does not exist.</P>
    </>
  )
}
