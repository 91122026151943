import { ChatBotForm } from "@/components/chatbot-form.tsx"
import { Button } from "@/components/ui/button.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc.ts"
import { Loader2 } from "lucide-react"
import { useNavigate } from "react-router"
import invariant from "tiny-invariant"

export async function chatBotCreatePageLoader() {
  const account = authenticateGuard()
  invariant(account.role === "admin", "Account is required")
  return null
}

export function ChatBotCreatePage() {
  const navigate = useNavigate()

  return (
    <>
      <H1 className="mb-4">Create Chatbot</H1>
      <Lead className="mb-8 text-balance">Create a new Chatbot below.</Lead>
      <ChatBotForm
        onSubmit={async (values) => {
          await trpc.chatbots.create.mutate({
            name: values.name,
            description: values.description,
            internal: values.internal,
            role: values.role,
            context: values.context,
            rules: values.rules,
            openingMessage: values.openingMessage,
            returnMessage: values.returnMessage,
            emailWhitelist: values.emailWhitelist,
            userMessageHandling: values.userMessageHandling,
            knowledgeIds: values.knowledge.map((k) => k.id),
            slug: values.slug,
            capabilities: {
              datalake: values.datalake,
              contextPanel: values.contextPanel,
              chatHistory: values.chatHistory,
              chatThreads: values.chatThreads,
            },
          })
          // Chatbots show up in nav, so force navigate
          window.location.href = "/admin/chatbots"
        }}
        actions={(state) => (
          <div className="flex flex-grow justify-between">
            <div className="flex gap-3">
              <Button
                type="submit"
                variant="default"
                disabled={state.isSubmitting || state.isLoading}
              >
                {(state.isSubmitting || state.isLoading) && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                Create ChatBot
              </Button>
              <Button
                type="button"
                variant="secondary"
                disabled={state.isSubmitting || state.isLoading}
                onClick={() => navigate("/admin/chatbots")}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      />
    </>
  )
}
