import { GdriveExplorer } from "@/components/gdrive-explorer.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { formatSize } from "@/lib/format.ts"
import { trpc } from "@/lib/trpc.ts"
import { Loader2 } from "lucide-react"
import React from "react"
import { useLoaderData } from "react-router"
import invariant from "tiny-invariant"

export async function gdriveListPageLoader() {
  const account = authenticateGuard()
  invariant(account.role === "admin", "Account is required")

  const [syncStatus, meta] = await Promise.all([
    trpc.knowledge.getLastGdriveSync.query(),
    trpc.knowledge.getGdriveMeta.query(),
  ])

  return { syncStatus, meta }
}

type LoaderData = Awaited<ReturnType<typeof gdriveListPageLoader>>

export function GdriveListPage() {
  const data = useLoaderData() as LoaderData
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <H1 className="mb-4">Files</H1>
      <Lead className="mb-8 text-balance">
        Below are the files that can be used when defining Knowledge for our ChatBots to use. These
        files are available via the NetLife AI Google Drive account ("netlifeai@gsmsystems.com"). To
        make files available you need to share them with the NetLife AI Google account.
      </Lead>
      <div className="mb-4 flex flex-row items-center justify-end gap-4">
        {data.syncStatus && (
          <div className="text-sm text-gray-500">
            {data.syncStatus.status === "completed" &&
              `Last checked at ${new Date(data.syncStatus.timeUpdated).toLocaleString()}`}
          </div>
        )}
        <Button
          size="sm"
          disabled={
            data.syncStatus &&
            (data.syncStatus.status === "pending" || data.syncStatus.status === "in_progress")
          }
          onClick={async () => {
            await trpc.knowledge.startGdriveSync.mutate()
            window.location.reload()
          }}
        >
          {data.syncStatus &&
            (data.syncStatus.status === "pending" || data.syncStatus.status === "in_progress") && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
          Check Google Drive For Updates
        </Button>
      </div>

      <div className="flex flex-col gap-6">
        <Card>
          <CardHeader>
            <CardTitle>File Statistics</CardTitle>
          </CardHeader>
          <CardContent className="grid grid-cols-2 gap-6">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Total Files</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{data.meta.fileCount}</div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Total File Size</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{formatSize(data.meta.totalSize)}</div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Total Folders</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{data.meta.folderCount}</div>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
        <Drawer open={open} onOpenChange={setOpen}>
          <DrawerTrigger>
            <Button type="button" size="sm" className="hidden">
              File Explorer
            </Button>
          </DrawerTrigger>
          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>Gdrive Explorer</DrawerTitle>
              <DrawerDescription>
                These are the folders and files that are available in the NetLife AI Google Drive.
                Click a folder to expand it.
              </DrawerDescription>
            </DrawerHeader>
            <div className="h-[60vh] overflow-auto">
              <GdriveExplorer />
            </div>
            <DrawerFooter>
              <DrawerClose>
                <Button variant="outline">Cancel</Button>
              </DrawerClose>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
    </>
  )
}
