import { type ControllerRenderProps } from "react-hook-form"

import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx"
import { Switch } from "@/components/ui/switch.tsx"
import { cn } from "@/lib/tw-utils.ts"
import { P } from "../ui/typography.tsx"

export type SwitchFieldProps = {
  field: ControllerRenderProps<any, any>
  description: string | React.ReactNode
  label: string
  switchPosition?: "left" | "right"
  onCheckedChanged?: (checked: boolean) => void
}

export function SwitchField(props: SwitchFieldProps) {
  const switchElement = (
    <FormControl>
      <Switch
        className="my-2"
        ref={props.field.ref}
        checked={props.field.value}
        name={props.field.name}
        onBlur={props.field.onBlur}
        type="button"
        onCheckedChange={(checked) => {
          props.onCheckedChanged?.(checked)
          props.field.onChange(checked)
        }}
      />
    </FormControl>
  )

  const labelElement = (
    <div className="flex-grow space-y-1">
      <FormLabel htmlFor={props.field.name}>{props.label}</FormLabel>
      <div className="text-muted-foreground">
        {typeof props.description === "string" ? (
          <P className="text-sm lg:text-sm">{props.description}</P>
        ) : (
          props.description
        )}
      </div>
    </div>
  )

  return (
    <FormItem>
      <div
        className={cn(
          "flex items-center justify-between gap-8",
          props.switchPosition === "left" && "items-start",
        )}
      >
        {props.switchPosition === "left" ? switchElement : labelElement}
        {props.switchPosition === "left" ? labelElement : switchElement}
      </div>
      <FormMessage />
    </FormItem>
  )
}
