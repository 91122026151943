import { ChatBotCard } from "@/components/chatbot-card.tsx"
import { Main } from "@/components/layout.tsx"
import { Section } from "@/components/ui/page-layout.tsx"
import { H1, H2, Lead } from "@/components/ui/typography.tsx"
import { trpc } from "@/lib/trpc.ts"
import { useLoaderData } from "react-router"

export async function dashboardLoader() {
  const chatBots = await trpc.chatbots.list.query()
  const externalBots = chatBots.filter((x) => !x.internal)
  const internalBots = chatBots.filter((x) => !!x.internal)
  return { externalBots, internalBots }
}

type LoaderData = Awaited<ReturnType<typeof dashboardLoader>>

export function DashboardPage() {
  const data = useLoaderData() as LoaderData

  return (
    <Main>
      <H1 className="mb-4">ChatBots</H1>
      <Lead className="mb-8 max-w-5xl text-balance">
        Our suite of AI-powered chatbots are designed to enhance your experience by providing
        instant access to a range of training materials, business insights, and services. Below are
        the ChatBots that are currently available to you.
      </Lead>
      <div className="flex flex-wrap gap-6 lg:gap-8">
        {data.externalBots.map((chatBot) => (
          <ChatBotCard
            key={chatBot.id}
            title={chatBot.name}
            description={chatBot.description}
            link={`/chat/${chatBot.slug ?? chatBot.id}`}
          />
        ))}
      </div>
      {data.internalBots.length > 0 && (
        <Section>
          <H2>Internal ChatBots</H2>
          <div className="mt-6 flex flex-wrap gap-6 lg:mt-6 lg:gap-8">
            {data.internalBots.map((chatBot) => (
              <ChatBotCard
                key={chatBot.id}
                title={chatBot.name}
                description={chatBot.description}
                link={`/chat/${chatBot.slug ?? chatBot.id}`}
              />
            ))}
          </div>
        </Section>
      )}
    </Main>
  )
}
