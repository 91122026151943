import { NavMain } from "@/components/nav-main.tsx"
import { NavSecondary } from "@/components/nav-secondary.tsx"
import { NavUser } from "@/components/nav-user.tsx"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar.tsx"
import { Session } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc.ts"
import { BookOpen, Bot, FileText, MessageCircleQuestionIcon, ScrollText, Users } from "lucide-react"
import * as React from "react"
import { Link } from "react-router"

export type AppSidebarProps = {
  chatBots: Awaited<ReturnType<typeof trpc.chatbots.list.query>>
  session: Session
} & React.ComponentProps<typeof Sidebar>

export function AppSidebar({ chatBots, session, ...props }: AppSidebarProps) {
  const data = React.useMemo(() => {
    return {
      navMain: [
        {
          title: "ChatBots",
          url: "/",
          isActive: true,
          icon: Bot,
          //items: chatBots.map((chatBot) => ({
          //  title: chatBot.name,
          //  url: `/chat/${chatBot.slug ?? chatBot.id}`,
          //})),
        },
        //{
        //  title: "Documentation",
        //  url: "#",
        //  icon: BookOpen,
        //  items: [
        //    {
        //      title: "Introduction",
        //      url: "#",
        //    },
        //    {
        //      title: "Get Started",
        //      url: "#",
        //    },
        //    {
        //      title: "Tutorials",
        //      url: "#",
        //    },
        //    {
        //      title: "Changelog",
        //      url: "#",
        //    },
        //  ],
        //},
        //{
        //  title: "Settings",
        //  url: "#",
        //  icon: Settings2,
        //  items: [
        //    {
        //      title: "General",
        //      url: "#",
        //    },
        //    {
        //      title: "Team",
        //      url: "#",
        //    },
        //    {
        //      title: "Billing",
        //      url: "#",
        //    },
        //    {
        //      title: "Limits",
        //      url: "#",
        //    },
        //  ],
        //},
      ],
      navSecondary: [
        {
          title: "WhatsApp Support",
          url: "https://chat.whatsapp.com/H3WMFwSmXyY3XYVFkuNE0o",
          icon: MessageCircleQuestionIcon,
        },
        //{
        //  title: "Feedback",
        //  url: "#",
        //  icon: Send,
        //},
      ],
      navAdministration: [
        {
          title: "ChatBots",
          url: "/admin/chatbots",
          icon: Bot,
        },
        {
          title: "Knowledge",
          url: "/admin/knowledge",
          icon: BookOpen,
        },
        {
          title: "Files",
          url: "/admin/files",
          icon: FileText,
        },
        {
          title: "Users",
          url: "/admin/users",
          icon: Users,
        },
        {
          title: "Release Notes",
          url: "/admin/release-notes",
          icon: ScrollText,
        },
      ],
      projects: [],
    }
  }, [chatBots, session])
  return (
    <Sidebar variant="inset" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <Link to="/">
                <div className="text-sidebar-primary-foreground flex aspect-square size-8 items-center justify-center rounded-lg">
                  <img src="/favicon.svg" alt="Logo" className="size-8" />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">NetLife</span>
                  <span className="truncate text-xs">Artificial Intelligence</span>
                </div>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavMain title="Platform" items={data.navMain} />
        {session?.account?.role === "admin" ? (
          <NavMain title="Administration" items={data.navAdministration} />
        ) : null}
        <NavSecondary items={data.navSecondary} className="mt-auto" />
      </SidebarContent>
      <SidebarFooter>
        <NavUser session={session} />
      </SidebarFooter>
    </Sidebar>
  )
}
