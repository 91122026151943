import { DataTable } from "@/components/datatable.tsx"
import { Button } from "@/components/ui/button.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { trpc } from "@/lib/trpc.ts"
import { ColumnDef } from "@tanstack/react-table"
import { useLoaderData, useNavigate } from "react-router"

export async function chatBotListPageLoader() {
  const chatBots = await trpc.chatbots.list.query()
  return { chatBots }
}

type LoaderData = Awaited<ReturnType<typeof chatBotListPageLoader>>

type Record = LoaderData["chatBots"][number]

export const columns: ColumnDef<Record>[] = [
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "internal",
    header: "Internal",
    cell: ({ getValue }) => {
      const value = getValue() as boolean
      return value ? "Yes" : ""
    },
  },
  {
    accessorKey: "timeUpdated",
    header: "Updated",
    cell: ({ getValue }) => {
      const value = getValue() as string
      return value.split(" ")[0]
    },
  },
]

export function ChatBotListPage() {
  const data = useLoaderData() as LoaderData
  const navigate = useNavigate()
  return (
    <>
      <H1 className="mb-4">Chat Bots</H1>
      <Lead className="mb-8 text-balance">
        Manage your chat bots here. If your chat Chat Bot needs access to documents please ensure
        that you have configured "Knowledge" within the Knowledge management section.
      </Lead>
      <div className="mb-4 flex justify-end">
        <Button variant="default" size="sm" onClick={() => navigate("/admin/chatbots/create")}>
          Create ChatBot
        </Button>
      </div>
      <DataTable
        columns={columns}
        data={data.chatBots}
        onRowClicked={(row: Record) => {
          navigate(`/admin/chatbots/${row.slug ?? row.id}`)
        }}
      />
    </>
  )
}
